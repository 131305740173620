<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center ">
      <CCol md="6">
        <div class="w-100">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">404</h1>
            <h4 class="pt-3">Oops! Encontramos um problema...</h4>
            <p class="text-muted">{{errorApplication.message}}</p>
          </div>
          <CButton color="blue-dark" class="float-center px-4" @click="retry">TENTAR NOVAMENTE</CButton>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { checkTenant } from "@/js/modules/auth.js";
export default {
  name: 'Page404',
  computed: {
    errorApplication() {
      return this.$store.state.errorApplication;
    },
  },
  methods: {
    checkTenant,
    retry() {
      this.$router.push({ path: "#" });
      this.checkTenant()
    }
  }
}
</script>
